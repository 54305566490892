export const HeaderNavegationList = [
  {
    navTitle: 'Análise',
    idNav: 'nav-dropdown-analysis',
    visible: false,
    navItem: [
      {
        title: 'Carga',
        route: '/file-load',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL', 'SCRAP_COMPRADOR'],
      },
      {
        title: 'Analítico',
        route: '/analitico',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Sintético',
        route: '/sintetico',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Gerencial',
        route: '/gerencial',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Equalização',
        route: '/equalizacao',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Indicador',
        route: '/indicador',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Pesquisar Item na Rede',
        route: '/consulta-item-rede',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Campanhas Ativas',
        route: '/campanhas-ativas',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
    ],
  },
  {
    navTitle: 'Fornecedor',
    idNav: 'nav-dropdown-provider',
    visible: false,
    navItem: [
      {
        title: 'Cadastro de Itens Originais',
        route: '/itens-originais',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
      {
        title: 'Cadastrar Status de Item Original',
        route: '/itens-originais-status',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
      {
        title: 'Cadastrar Item Correlato',
        route: '/itens-correlato',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
      {
        title: 'Cadastrar Filial Fornecedor',
        route: '/filial-fornecedor',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
      {
        title: 'Cadastrar Setor',
        route: '/setor',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Regional',
        route: '/regional',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Carregar Arquivos Fornecedor',
        route: '/carregar-arquivos-fornecedor',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
    ],
  },
  {
    navTitle: 'Segurança',
    idNav: 'nav-dropdown-security',
    visible: false,
    navItem: [
      {
        title: 'Troca de Usuário',
        route: '/troca-usuario',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Grupo da Empresa',
        route: '/grupo-empresa',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
    ],
  },
  {
    navTitle: 'Scrap',
    idNav: 'nav-dropdown-scrap',
    visible: false,
    navItem: [
      {
        title: 'Cadastro Filtro Scrap',
        route: '/filtro-scrap',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
      {
        title: 'Aprovação Scrap',
        route: '/aprovacao-scrap',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR', 'SCRAP_AUDITOR', 'SCRAP_CONSULTOR', 'SCRAP_FINANCEIRO', 'SCRAP_VENDAS'],
      },
      {
        title: 'Consulta de Scrap',
        route: '/consulta-scrap',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR'],
      },
      {
        title: 'Gerar Scrap',
        route: '/gerar-scrap',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_COMPRADOR'],
      },
      {
        title: 'Relatório Scrap',
        route: '/relatorio-scrap',
        disable: true,
        visible: false,
        role: ['ADMIN', 'SCRAP_ADMINISTRADOR', 'SCRAP_AUDITOR', 'SCRAP_FINANCEIRO', 'SCRAP_VENDAS'],
      },
    ],
  },
  {
    navTitle: 'Configurações',
    idNav: 'nav-dropdown-configuration',
    visible: false,
    navItem: [
      {
        title: 'Cadastrar Valor da Curva',
        route: '/valor-curva',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Pontuação Curva',
        route: '/pontuacao-curva',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Giro da Curva Empresa',
        route: '/giro-curva-empresa',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Giro da Curva',
        route: '/giro-curva-usuario',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Consultar Log Carga',
        route: '/log-carga',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Mensagem',
        route: '/mensagem',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Comunicado',
        route: '/comunicacao',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Observação em Item',
        route: '/observacao-item',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_OPERACIONAL', 'COMPRAS_GESTOR'],
      },
      {
        title: 'Cadastrar Feriado',
        route: '/feriado',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Cadastrar Configuração',
        route: '/cadastrar/configuracao',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Fabricante',
        route: '/fabricante',
        disable: true,
        visible: false,
        role: ['ADMIN', 'COMPRAS_GESTOR', 'COMPRAS_OPERACIONAL'],
      },
      {
        title: 'Cadastrar Fornecedor',
        route: '/cadastrar/fornecedor',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Regras de Consultor Online',
        route: '/config-consultor-online-v2',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastro de Ordenação',
        route: '/ordenacao',
        disable: true,
        visible: true,
        role: ['ADMIN'],
      },
      {
        title: 'Cadastrar Usuário',
        route: '/usuario',
        disable: true,
        visible: false,
        role: ['ADMIN'],
      },
      {
        title: 'Tutorial de Telas',
        route: '/tutorial',
        disable: true,
        visible: true,
        role: ['ADMIN'],
      },
    ],
  },
];
