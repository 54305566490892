import { Row, Col, Card } from "react-bootstrap";
import { ActiveCampaignTotals } from "../../../../@types/interfaces/analysis/active-campaign-totals-interface";

interface PropsActiveCampaignTotalsCard {
  activeCampaignTotals: ActiveCampaignTotals;
}

const valorZerado = 0

export const ActiveCampaignTotalsCard: React.FC<PropsActiveCampaignTotalsCard> = ({ activeCampaignTotals }) => {
  if (activeCampaignTotals !== null && activeCampaignTotals !== undefined) {
    return (
      <Row className="align-items-center justify-content-center">
        <Col xs={12} className="mb-2">
          <Card style={{ padding: "0px 20px" }}>
            <Row >
              <Col>
                <Row className="align-items-center justify-content-center active-campaign-total-number" >
                  {activeCampaignTotals.comStComDesconto ?
                    activeCampaignTotals.comStComDesconto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', })
                    : valorZerado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', })}
                </Row>
                <Row className="align-items-center justify-content-center active-campaign-total-text">
                  Total Preço com ST com Desconto
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center justify-content-center active-campaign-total-number" >
                  {activeCampaignTotals.semStComDesconto ?
                    activeCampaignTotals.semStComDesconto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', })
                    : valorZerado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', })}
                </Row>
                <Row className="align-items-center justify-content-center active-campaign-total-text">
                  Total Preço sem ST com Desconto
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  } else {
    return (<></>)
  }
}