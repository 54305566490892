import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter";
import { ParseDateToBr } from "helper/format-date-helper";
import { getEnv } from "helper/window-helper";
import { UseAuth } from "hook/auth-hook";
import { FixedHeader } from "pages/style/table/table-fixed-header";
import { useState, useEffect } from "react";
import { Container, Card, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RestUseCase } from "service/api/rest/rest-usecase";
import { Headers } from 'components/header/headers-component';

interface ErrorType {
    message: string;
}

interface CargaLogInterface {
    arqFilIntCod: number;
    filStrNmeFantasia: String;
    arqDtmCadastroUlt: string;
    statusExtrator: string;
    ultimaCompra: string;
    ultimaVenda: string;
}

interface ComprasUpdatesInterface {
    id: number;
    localDescricao: string;
    data: Date;
    descricao: string;
    modulo: string;
}

const Home: React.FC = () => {

    const ENDPOINT_CARGA_LOG = getEnv('REACT_APP_END_POINT_CARGA_LOG');
    const ENDPOINT_COMPRAS_UPDATE = getEnv('REACT_APP_END_POINT_COMPRAS_UPDATE');

    // Estados para armazenar os dados de carga, carregamento e erro
    const [comprasUpdates, setComprasUpdates] = useState<ComprasUpdatesInterface[]>([]);
    const [cargaLogs, setCargaLogs] = useState<CargaLogInterface[]>([]);
    const [isLoadingCarga, setIsLoadingCarga] = useState(false);
    const [isLoadingUpdates, setIsLoadingUpdates] = useState(false);
    const [error, setError] = useState<ErrorType | null>(null);

    const { loggedUserData } = UseAuth()
    let navigate = useNavigate()

    function Loading() {
        return (
            <div style={{ display: "block", textAlign: "center", marginTop: "30px" }}>
                <div className="spinner-border text-secondary" role="status"></div>
            </div>
        );
    }

    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(header => header.navTitle === "Scrap")[0].navItem.filter(item => item.title === "Gerar Scrap")[0].role

        if (loggedUserData?.role) {
            loggedUserData.role.forEach(value => {
                if (roleScreen.includes(value))
                    navigate("/")
            })
        }

        fetchCargaLogs(loggedUserData?.companyId);
        getComprasUpdates();
    }, [loggedUserData])

    const getComprasUpdates = async () => {
        setIsLoadingUpdates(true);
        try {
            const response = await new RestUseCase(ENDPOINT_COMPRAS_UPDATE).Get();
            setComprasUpdates(response.body);

        } catch (err: any) {
            console.error("Erro ao buscar dados de atualizações:", err);
        } finally {
            setIsLoadingUpdates(false);
        }
    }

    const fetchCargaLogs = async (companyId?: number) => {
        setIsLoadingCarga(true);
        if ((companyId !== null) && (companyId !== undefined)) {
            try {
                const id = companyId;
                const response = await new RestUseCase(`${ENDPOINT_CARGA_LOG}/${id}`).Get();
                setCargaLogs(response.body);

            } catch (err: any) {
                setError({ message: err.message });
                console.error("Erro ao buscar dados de carga:", err);
            } finally {
                setIsLoadingCarga(false);
            }
        }
    };

    return (
        <>
            <Headers />
            <Container>
                <h1 className="mt-3">Bem vindo ao novo IS-COMPRAS</h1>
                {(isLoadingCarga || isLoadingUpdates) && <p>Carregando dados...</p>}
                {error && <p>Erro ao carregar dados: {error.message}</p>}
                {(!isLoadingUpdates) ? (
                    <Card>
                        <FixedHeader style={{ maxHeight: "300px" }}>
                            <Table striped bordered >
                                <thead>
                                    <tr >
                                        <th colSpan={6} className="text-center" style={{ background: "#FFFFFF" }}>
                                            Atualizações
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="text-center" style={{ background: "#FFFFFF" }}>Data</th>
                                        <th className="text-center" style={{ background: "#FFFFFF" }}>Local</th>
                                        <th className="text-center" style={{ background: "#FFFFFF" }}>Descrição</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center" >
                                    {(comprasUpdates && comprasUpdates.length > 0) ?
                                        comprasUpdates.map((comprasUpdate) => (
                                            <tr key={comprasUpdate.id}>
                                                <td>{ParseDateToBr(comprasUpdate.data.toString())}</td>
                                                <td>{comprasUpdate.localDescricao}</td>
                                                <td>{comprasUpdate.descricao}</td>
                                            </tr>
                                        ))
                                        :
                                        <></>}
                                </tbody>
                            </Table>
                        </FixedHeader>
                    </Card>
                ) : <Loading />}
                <div className="mt-3" style={{ width: "100%", border: "1px solid black" }}></div>
                {!isLoadingCarga ? (
                    <Card className="mt-3">
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th colSpan={6} className="text-center">
                                        Validação dos últimos dados recebidos do seu ERP
                                    </th>
                                </tr>
                                <tr>
                                    <th className="text-center">Filial</th>
                                    <th className="text-center">Extrator</th>
                                    <th className="text-center">Último processamento</th>
                                    <th className="text-center">Última compra</th>
                                    <th className="text-center">Última venda</th>
                                </tr>
                            </thead>
                            <tbody className="text-center" >
                                {(cargaLogs && cargaLogs.length > 0) ?
                                    cargaLogs.map((cargaLog) => (
                                        <tr key={cargaLog.arqFilIntCod}>
                                            <td>{cargaLog.filStrNmeFantasia}</td>
                                            <td>{cargaLog.statusExtrator}</td>
                                            <td>
                                                {cargaLog.arqDtmCadastroUlt}
                                            </td>
                                            <td>{cargaLog.ultimaCompra}</td>
                                            <td>{cargaLog.ultimaVenda}</td>
                                        </tr>
                                    ))
                                    :
                                    <></>}
                            </tbody>
                        </Table>
                    </Card>
                ) : <Loading />}
            </Container>
        </>
    )
}

export default Home