/* eslint-disable no-restricted-globals */
import jwt_decode from 'jwt-decode';

import api from 'service/api/api-infra';
import { AxiosResponse } from 'axios';
import { HttpRequest } from '../../../@types/http-type';
import { getLocalStorage } from 'data/cache/localstorage-cache';
import { getEnv } from 'helper/window-helper';

function checkRouteContent(url: string): string | null {
  const match = url.match(/\/([^/]+)/);
  if (match) {
    return match[1];
  }
  return null;
}

export async function CallHttp(request: HttpRequest) {
  let axiosResponse: AxiosResponse;
  const TOKEN_USER_LOGIN = getEnv('REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN')!;
  const TOKEN_USER_CHANGE = getEnv('REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE')!;
  const TYPE_SERVER = getEnv('REACT_APP_SERVER_ENVIRONMENT')!;

  let Typetoken = 'LOGIN';

  const tokenLocalStorageLogin: {
    token: string
  } =
    getLocalStorage(TOKEN_USER_LOGIN);
  const tokenLocalStorageChange: {
    token: string
  } =
    getLocalStorage(TOKEN_USER_CHANGE);

  if (!tokenLocalStorageChange) {
    api.defaults.headers.common = {
      Authorization: `Bearer ${tokenLocalStorageLogin.token}`,
    };
    var decodedLogin = jwt_decode(tokenLocalStorageLogin.token) as any;

    if (Date.now() >= decodedLogin.exp * 1000) {
      localStorage.clear();
      location.replace('/error-token');
      location.reload();
    }
  } else {
    Typetoken = 'CHANGE';
    api.defaults.headers.common = {
      Authorization: `Bearer ${tokenLocalStorageChange.token}`,
    };
    var decodedChange = jwt_decode(tokenLocalStorageChange.token) as any;

    if (Date.now() >= decodedChange.exp * 1000) {
      localStorage.clear();
      location.replace('/error-token');
      location.reload();
    }
  }

  let baseURL = process.env.REACT_APP_API_BACKEND!;

  if (TYPE_SERVER === 'local') {
    let route = checkRouteContent(request.url);
    if (route !== null) {
      switch (route) {
        case 'seguranca':
          baseURL = process.env.REACT_APP_API_SERVICE_SEGURANCA!;
          break;
        case 'configuracao':
          baseURL = process.env.REACT_APP_API_SERVICE_CONFIGURACAO!;
          break;
        case 'analise-operacional':
          baseURL =
            process.env.REACT_APP_API_SERVICE_ANALISE_OPERACIONAL!;
          break;
        case 'fornecedor':
          baseURL = process.env.REACT_APP_API_SERVICE_FORNECEDOR!;
          break;
        case 'carga-operacional':
          baseURL =
            process.env.REACT_APP_API_SERVICE_CARGA_OPERACIONAL!;
          break;
        case 'scrap':
          baseURL = process.env.REACT_APP_API_SERVICE_SCRAP!;
          break;
      }
    }
  }

  try {
    axiosResponse = await api.request({
      baseURL: baseURL,
      url: request.url,
      method: request.method,
      data: request.body,
      headers: request.headers,
      responseType: request.responseType,
    });

    console.warn('CHAMADA COM SUCESSO', {
      request_url: request.url,
      request_method: request.method,
      request_headers: request.headers,
      request_responseType: request.responseType,
      token: tokenLocalStorageLogin,
      typeToken: Typetoken,
      response_data: axiosResponse.data,
      response_status: axiosResponse.status,
    });

    if (!tokenLocalStorageLogin) {
      localStorage.clear();
      location.reload();
    }

    return {
      statusCode: axiosResponse.status,
      headers: axiosResponse.headers,
      body: axiosResponse.data,
      error: null,
    };
  } catch (error: any) {
    axiosResponse = error.response;

    console.error('CHAMADA COM ERRO', {
      request: request,
      token: tokenLocalStorageLogin,
      typeToken: Typetoken,
      response: axiosResponse,
    });

    return {
      statusCode: axiosResponse.status,
      headers: axiosResponse.headers,
      body: null,
      error: {
        message: error.message,
        data: error.response?.data,
      },
    };
  }
}
