/* eslint-disable no-restricted-globals */
import { UseAuth } from "hook/auth-hook"
import { Container, Nav, Image, Navbar } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { ReactComponent as SVGIcon } from "../../assets/images/logo-is-compras.svg"
import { useEffect, useState } from "react"
import { RestUseCase } from "service/api/rest/rest-usecase"
import { getEnv } from "helper/window-helper";
import { clearLocalStoragePlanning } from "data/cache/localstorage-cache"


export function TopHeader() {

    const { updateIsLoggedIn, loggedUserData } = UseAuth()
    const avatar = "https://gravatar.com/avatar/9d6cbf90c3a007688af389adf8ca038d?s=400&d=identicon&r=x"

    let navigate = useNavigate()

    function handleLogout(): void {
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_LOGIN"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_TOKEN_USER_CHANGE"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_VIEW_COMMUNIQUE"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_COLUMS_ANALYTICAL"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_COLUMS_MANAGERIAL"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_COLUMS_SYNTHETIC"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_COLUMS_EQUALIZATION"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_COLUMS_ACTIVE_CAMPAIGN"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_ANALYTICAL"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_SYNTHETIC"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_EQUALIZATION"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_INDICATOR"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_MENAGERIAL"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_SCRAP"))
        localStorage.removeItem(getEnv("REACT_APP_LOCALSTORAGE_PLANNING_ACTIVE_CAMPAIGN"))
        clearLocalStoragePlanning()

        updateIsLoggedIn(false)
        navigate("/")
        location.reload()
    }

    const ENDPOINT_COMPANY = getEnv("REACT_APP_END_POINT_COMPANIES")!;
    const ENDPOINT_USER = getEnv("REACT_APP_END_POINT_USER")!;
    const [user, updateUser] = useState<any>();
    const [company, updateCompany] = useState<any>();

    async function getUser(id: number): Promise<any> {
        await new RestUseCase(ENDPOINT_USER + `/${id}`).GetById().then((response) => {
            updateUser(response.body);
        })
    }

    async function getCompany(id: number): Promise<any> {
        await new RestUseCase(ENDPOINT_COMPANY + `/${id}`).GetById().then((response) => {
            updateCompany(response.body)
        })
    }

    useEffect(() => {
        if (loggedUserData !== undefined) {
            getUser(loggedUserData.id)
            getCompany(loggedUserData.companyId)
        }
    }, [loggedUserData])

    return (
        <>
            <Navbar expand="lg" style={{ backgroundColor: 'rgba(202, 220, 251, 1)' }}>
                <Container>
                    <Navbar.Brand><SVGIcon height={"40px"} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="firstHeader" />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <b>Usuário:</b>{user?.nome}<b> Empresa:</b>{company?.nomeFantasia}
                        </Navbar.Text>
                        <Image src={avatar} roundedCircle={true} width="40px" height="40px" className="mx-2" />
                        <Nav.Link onClick={() => handleLogout()} style={{ color: "#017BFE" }}>
                            Sair
                        </Nav.Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}