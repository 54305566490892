/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Select from "react-select";
import { BsSliders } from "react-icons/bs";
import { useEffect, useState } from "react";
import { RestUseCase } from "service/api/rest/rest-usecase";
import { UseAuth } from "hook/auth-hook";
import ReportPage from "pages/layout/scrap/report-page";
import { Painel } from "components/painel/peinel-component";
import {
    Button,
    Col,
    Container,
    Badge,
    Form,
    InputGroup,
    Nav,
    Row,
    Modal,
} from "react-bootstrap";
import { ConfigTable } from "../../../@types/interfaces/table-interface";
import CreateModalReport from "components/table/table-modal-component";
import {
    getParameterUrlByName,
    parseLinkHeader,
} from "helper/request-helper";
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface";
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page";
import { ModalUploadFile } from "components/modal/modal-upload-file-component";
import { ModalListItemGenerateScrap } from "components/modal/modal-list-item-generate-scrap-component";
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter";
import { UseAlert } from "hook/alert-hook";
import {
    getLocalStorage,
    updateLocalStorege,
} from "data/cache/localstorage-cache";
import { handleRequest } from "service/api/request-common";
import {
    ParseDateToBrV2,
    ParseDateToBrWithHours,
} from "helper/format-date-helper";
import { ScrapFilterPlanningAdapter } from "helper/adapter/planning/scrap-filter-planning-adapter";
import { getEnv } from "helper/window-helper";

interface LinkHeader {
    first: string;
    last: string;
    next: string;
    prev: string;
}

interface EntityFieldReport {
    name: string;
    labelTable: string;
    typeField: string;
    align: "left" | "center" | "right";
}

const LOCALSTORAGE_PLANNING =
    getEnv("REACT_APP_LOCALSTORAGE_PLANNING_SCRAP")!;
const ENDPOINT_PLANNING =
    getEnv("REACT_APP_END_POINT_ANALYTICAL") + "/filtros";
const ENDPOINT_CURRENT_FORNECEDOR =
    getEnv("REACT_APP_END_POINT_CURRENT_FILIAL_PROVIDER");
const ENDPOINT_MANUFACTURER = getEnv("REACT_APP_END_POINT_MANUFACTURER")!
getEnv("REACT_APP_END_POINT_CURRENT_FILIAL_PROVIDER");
const ENDPOINT_LOCATIONS = getEnv('REACT_APP_END_POINT_LOCATIONS');

export const GenerateScrapFactory: React.FC = () => {
    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** CAMPOS DA TELA */
    const entityFieldsHistoric: EntityFieldReport[] = [
        {
            name: "id",
            labelTable: "Código Scrap",
            typeField: "string",
            align: "center",
        },
        {
            name: "dataCadastro",
            labelTable: "Cadastro",
            typeField: "date",
            align: "center",
        },
        {
            name: "dataAgendamento",
            labelTable: "Agendamento",
            typeField: "date",
            align: "center",
        },
        {
            name: "status",
            labelTable: "Status",
            typeField: "text",
            align: "left",
        },
        {
            name: "nomeAuditor",
            labelTable: "Auditor",
            typeField: "string",
            align: "left",
        },
        {
            name: "nomeFilialFornecedor",
            labelTable: "Filial",
            typeField: "text",
            align: "left",
        },
        {
            name: "valorTotalScrap",
            labelTable: "Total",
            typeField: "monetary",
            align: "left",
        },
    ];
    const entityFieldsScrap: EntityFieldReport[] = [
        {
            name: "codigo",
            labelTable: "Código",
            typeField: "text",
            align: "left",
        },
        {
            name: "descricao",
            labelTable: "Descrição",
            typeField: "text",
            align: "left",
        },
        {
            name: "locacao",
            labelTable: "Locação",
            typeField: "text",
            align: "center",
        },
        {
            name: "dataUltimaCompra",
            labelTable: "Últ. Compra",
            typeField: "date",
            align: "center",
        },
        {
            name: "dataUltimaVenda",
            labelTable: "Últ. Venda",
            typeField: "date",
            align: "center",
        },
        {
            name: "precoGarantia",
            labelTable: "Prç. Gar",
            typeField: "monetary",
            align: "right",
        },
        {
            name: "precoReposicao",
            labelTable: "Prç. Rep",
            typeField: "monetary",
            align: "right",
        },
        {
            name: "custoMedio",
            labelTable: "Cst. Médio",
            typeField: "monetary",
            align: "right",
        },
        {
            name: "precoScrap",
            labelTable: "Prç. Scrap",
            typeField: "monetary",
            align: "right",
        },
        {
            name: "quantidadeEstoque",
            labelTable: "Qtd. Est",
            typeField: "number",
            align: "center",
        },
        {
            name: "quantidadeMaxima",
            labelTable: "Qtd. Limite",
            typeField: "number",
            align: "center",
        },
        {
            name: "quantidadeSalvaInput",
            labelTable: "Qtd. Salva",
            typeField: "inputNumber",
            align: "right",
        },
    ];
    const entityFieldsItems: EntityFieldReport[] = [
        {
            name: "item",
            labelTable: "Item",
            typeField: "number",
            align: "left",
        },
        {
            name: "precoItemOriginal",
            labelTable: "Preço Item Original",
            typeField: "monetary",
            align: "left",
        },
        {
            name: "preco",
            labelTable: "Preço",
            typeField: "monetary",
            align: "left",
        },
        {
            name: "quantidade",
            labelTable: "Quantidade",
            typeField: "number",
            align: "left",
        },
        {
            name: "quantidadeAuditor",
            labelTable: "Quantidade Auditor",
            typeField: "number",
            align: "left",
        },
        {
            name: "codigoItem",
            labelTable: "Código Item",
            typeField: "text",
            align: "left",
        },
        {
            name: "descItem",
            labelTable: "Descrição Item",
            typeField: "text",
            align: "left",
        },
    ];
    const [entityFields, updateEntityFieldsScrap] =
        useState<EntityFieldReport[]>(entityFieldsScrap);

    /**
     *
     *
     *
     *
     *  ultima carga / pesquisa */
    const END_POINT_LAST_CHARGE = getEnv("REACT_APP_END_POINT_LAST_CHARGE")!;
    const [lastSearch, updateLastSearch] = useState<string>();
    const [lastCharge, updateLastCharge] = useState<string>();
    const handleLastCharge = async () => {
        await new RestUseCase(END_POINT_LAST_CHARGE).Get().then((response) => {
            updateLastCharge(ParseDateToBrWithHours(response.body));
        });
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** TABELA */

    const [tableHeader, updateTableHeader] = useState<any[]>([]);
    const [tableItemsHeader, updateTableItemsHeader] = useState<any[]>([
        "id",
        "item",
        "precoItemOriginal",
        "preco",
        "quantidade",
        "quantidadeAuditor",
        "codigoItem",
        "descItem",
    ]);
    const [tableBody, updateTableBody] = useState<any[]>([]);
    const [tableItemsBody, updateTableItemsBody] = useState<any[]>([]);
    const clearTableBody = () => {
        updateTableBody([]);
    };
    const clearTableItemBody = () => {
        updateTableItemsBody([]);
    };
    /**
     *
     *
     *
     *
     *  alerta */
    const { showAlert } = UseAlert();

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** PLANEJAMENTO */
    const [showModalPlanning, updateShowModalPlanning] =
        useState<boolean>(false);
    const openModalPlanning = () => {
        updateShowModalPlanning(true);
    };
    const closeModalPlanning = () => {
        updateShowModalPlanning(false);
    };
    const [itemPlanning, updateItemPlanning] = useState<number>(0);
    async function handleApplyPlanning(model: any) {
        /** inicia o loading */
        /** fecha o modal */
        closeModalPlanning();
        let countPlanning = model.length;
        /** salva no localstorage */
        updateLocalStorege(LOCALSTORAGE_PLANNING, model);
        updateItemPlanning(countPlanning);

        try {
            if (navigationActive === "scrap") {
                const page: string = "0";
                const size: string = getParameterUrlByName(
                    "size",
                    nextPagination
                )!;
                await getAllScrap({ page: page, size: size });
            }
            if (countPlanning > 0)
                showAlert({
                    show: true,
                    content: "Planejamento Aplicado com sucesso",
                    color: "success",
                    time: 2000,
                });
            else
                showAlert({
                    show: true,
                    content: "Nenhum item aplicado",
                    color: "warning",
                    time: 2000,
                });
        } catch (error) {
            showAlert({
                show: true,
                content: "Ops!!! Algo deu errado tente novamente mais tarde...",
                color: "danger",
                time: 2000,
            });
        }
    }

    /**
     *
     *
     *
     *
     *  termo de aceite */
    const [showModalTermo, updateShowModalTermo] = useState<{
        open: boolean;
        openBy: string;
    }>({ open: false, openBy: "" });
    const closeTermo = (openBy: string) =>
        updateShowModalTermo({ open: false, openBy: openBy });
    const showTermo = (openBy: string) =>
        updateShowModalTermo({ open: true, openBy: openBy });

    function ModalTermo() {
        return (
            <Modal
                show={showModalTermo.open}
                onHide={() => closeTermo("")}
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className="ms-3 lead"
                        style={{ fontSize: "30px" }}
                    >
                        Termo do Scrap
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container
                        style={{
                            textAlign: "justify",
                            fontSize: "16px",
                            fontFamily: "Arial,sans-serif",
                            overflowY: "auto",
                            height: "300px",
                        }}
                    >
                        <p>
                            <strong>Ref.: TERMO DE ACEITE  SCRAP</strong>
                        </p>
                        <p>
                            <b>I - SELEÇÃO DOS ITENS</b>
                        </p>
                        <p>
                            1º - Todos os itens das listagens poderão ser
                            selecionados para SCRAP pela sua Concessionária,
                            porém, o valor a ser creditado pela FCA está
                            limitado ao valor de direito de acordo com a
                            política de comercialização em vigor.
                        </p>
                        <p>
                            2º - Caso a concessionária não tenha itens
                            suficientes em seu estoque que atinjam o valor de
                            direito de Scrap, conforme política de
                            comercialização vigente, o crédito à Concessionária
                            será apenas do montante referente aos itens
                            aprovados para o processo. A Concessionária não
                            poderá usufruir do valor residual, nos próximos
                            processos de Scrap.
                        </p>
                        <p>
                            3º - Não serão aceitos, para Scrap,
                            <b>
                                itens não genuínos, itens adquiridos nos últimos
                                6 (seis) meses, ferramentas, itens de Recall,
                                DFS e desenhos exclusivos de atendimento
                                Garantia.
                            </b>
                        </p>
                        <p>
                            4º - A listagem de peças selecionada para SCRAP
                            deverá conter exatamente os itens que serão
                            sucateados pela Concessionária e constar na nota
                            fiscal de baixa do estoque.
                        </p>
                        <p>
                            5º - Não é permitido a substituição dos itens
                            gerados na listagem selecionada para SCRAP, no
                            momento do sucateamento.
                        </p>
                        <p>
                            6º - Os itens da lista elegidos para Scrap e suas
                            respectivas quantidades serão definitivos somente
                            após aprovação do Auditor/CPV. Itens poderão ser
                            excluídos e quantidades poderão ser alteradas caso o
                            Auditor/CPV encontre divergências no momento do
                            sucateamento dos itens.
                        </p>
                        <p>
                            7º - A lista eleita para Scrap aprovada pelo
                            Auditor/CPV será a lista considerada para análise,
                            validação do processo da sua Concessionária e para
                            efeito de crédito.
                        </p>
                        <p>
                            <b>II - PROCEDIMENTOS GERAIS </b>
                        </p>
                        <p>
                            1º - O valor das peças para esta operação de Scrap
                            terá como referência a lista de preços mais atual,
                            em vigência, ao início do processo.
                        </p>
                        <p>Será utilizado o Preço Nota Fiscal sem ICMS-ST. </p>
                        <p>
                            2º - Após a conclusão do sucateamento a
                            Concessionária deverá emitir nota fiscal de baixa do
                            estoque. É <b>OBRIGATÓRIA</b>a utilização do
                            <b>
                                CFOP 5927 (baixa de estoque decorrente de perda,
                                roubo ou deterioração)
                            </b>
                            , não devendo ser emitido nenhum documento fiscal
                            contra a FCA S/A, contra terceiros ou CFOP que
                            caracterize transferência de estoque, venda ou
                            devolução.
                        </p>
                        <p>
                            3º - A nota fiscal eletrônica (NFe) deve ser
                            validada pela SEFAZ, para que o documento seja
                            considerado no processo de Scrap.
                        </p>
                        <p>
                            4º- Caso a Concessionária tenha filial e for de seu
                            interesse fazer Scrap em cada uma delas, será
                            necessário realizar o processo separadamente pelo
                            Login (usuário cadastrado no programa IS  Módulo
                            Scrap). As notas fiscais deverão ser emitidas para
                            seu respectivo processo e login de Scrap.
                        </p>
                        <p>
                            5º- Caso haja necessidade de remarcar a data do
                            Agendamento do Scrap, a Concessionária deverá avisar
                            seu Regional com no mínimo 7 (sete) dias úteis de
                            antecedência.
                        </p>
                        <p>
                            A Concessionária terá que aguardar disponibilidade
                            da agenda, com risco de perder o processo por causa
                            do prazo).
                        </p>
                        <p style={{ fontWeight: "bold", color: "red" }}>
                            Em hipótese alguma a Concessionária deverá sucatear
                            os itens sem a presença do Representante FCA.
                        </p>
                        <p>
                            6º- O sucateamento das peças deve garantir a efetiva
                            destruição da mesma, sendo de responsabilidade da
                            Concessionária providenciar o ferramental ou
                            maquinário necessário.
                            <span style={{ fontWeight: "bold", color: "red" }}>
                                Além disso devem ser tiradas várias fotos
                                datadas comprovando o efetivo sucateamento das
                                peças. As fotos devem ser enviadas para seu
                                Consultor de Pós-Vendas, para utilização junto
                                ao processo de Auditoria.
                            </span>
                        </p>
                        <p>
                            <b>III - Prazo do Processo de Scrap </b>
                        </p>
                        <p>1º- Prazos de entrega e pagamento: </p>
                        <p>
                            As concessionárias deverão realizar o UPLOAD DA NOTA
                            FISCAL (XML), no sistema até a data informada por
                            e-mail pelo seu Consultor de Pós-Vendas, do seu
                            Regional, assim como a data de pagamento do
                            processo.
                        </p>
                        <p>
                            <span style={{ fontWeight: "bold", color: "red" }}>
                                IMPORTANTE:
                            </span>
                            Não serão aceitos processos de Scrap entregues após
                            <b>o prazo informado por e-mail.</b>Caso a
                            Concessionária insira os arquivos XML no último dia
                            e houver alguma divergência, o processo poderá ser
                            cancelamento sem direito da Concessionária se
                            beneficiar do valor em processos posteriores.
                        </p>
                        <p>
                            2º - Atenção para a data de início e fim do processo
                            para que não haja o risco de perder o direito de
                            Scrap por causa de prazo de encerramento do
                            processo.
                        </p>
                        <p>
                            <u>
                                <b>IMPORTANTE:</b>
                            </u>
                        </p>
                        <p>
                            As peças não podem ser sucateadas sem um
                            representante da FCA.
                        </p>
                        <p>
                            O não cumprimento das regras pré-estabelecidas neste
                            termo e nos comunicados enviados pela equipe de
                            Scrap (Gerenciamento de Pedidos) ou Escritório
                            Regional acarretará no cancelamento do mesmo.
                        </p>
                        <p style={{ color: "red" }}>
                            OBS.: Não é necessário o envio físico ou por e-mail
                            deste documento.
                        </p>
                        <p>Li e aceito. </p>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={async () => {
                            if (showModalTermo.openBy === "Carregar") {
                                sendFileCSV();
                                closeTermo("");
                            }

                            if (showModalTermo.openBy === "Enviar") {
                                sendScrap();
                                closeTermo("");
                            }
                        }}
                    >
                        Aceitar
                    </Button>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={() => closeTermo("")}
                    >
                        Rejeitar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** NAVEGACAO */
    const [navigationActive, updateNavigationActive] =
        useState<string>("scrap");
    const changeNavigationActive = async (item: string) => {
        updateNavigationActive(item);
        if (item === "scrap") {
            configTable.openModal = false;
            updateEntityFieldsScrap(entityFieldsScrap);
            await getAllScrap({ page: "0", size: "50" });
        } else {
            updateEntityFieldsScrap(entityFieldsHistoric);
            await getAllHistoric({
                pagination: `?page=${0}&size=${totalPageSize}`,
                page: 0,
                size: totalPageSize,
            });
        }
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** CONFIGURACAO */
    const configTable: ConfigTable = {
        checkboxes: false,
        icons: true,
        buttonHeader: true,
        selectsTable: true,
        navegation: true,
        buttonFooter: true,
        pagination: true,
        planning: true,
        openModal: navigationActive === "scrap" ? false : true,
    };
    const configTableItems: ConfigTable = {
        checkboxes: false,
        icons: false,
        buttonHeader: false,
        selectsTable: false,
        navegation: false,
        buttonFooter: false,
        pagination: true,
        planning: false,
        openModal: false,
    };
    const ENDPOINT_CRUD = getEnv("REACT_APP_END_POINT_SCRAP")!;
    const ENDPOINT_PROVIDER = getEnv("REACT_APP_END_POINT_PROVIDER")!;
    const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")!;
    const titlePage = "Gerar Scrap";

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** CHAMADAS BACKEND */
    const [withError, updateWithError] = useState<boolean>(false);
    const [waiting, updateWaiting] = useState<boolean>(false);
    const [locationList, updateLocationList] = useState<SelectOptionInterface[]>([]);
    const loadScrapUtilizado = async () => {
        await new RestUseCase(
            `${ENDPOINT_CRUD}/salvo?idFornecedor=${provider?.value}&idFilial=${filial?.value}`
        )
            .Get()
            .then((response) => {
                setScrapValorUtilizado(response.body?.valorScrap ?? 0);
            })
            .catch(() => {
                showAlert({
                    show: true,
                    content: "Erro ao carregar valor utilizado do scrap",
                    color: "danger",
                    time: 1000,
                });
            });
    };

    async function listLocations(): Promise<any> {
        await new RestUseCase(ENDPOINT_LOCATIONS).Get().then((response) => {
            if (response.statusCode !== 500 && response.statusCode !== undefined) {
                updateLocationList(
                    response.body.locacoes.map((value: any) => {
                        return { value: value.codigo, label: value.codigo };
                    }),
                );
            }
        });
    }

    const getAllScrap = async (pagination: { page: string; size: string }) => {
        clearTableBody();
        if (
            provider !== undefined &&
            filial !== undefined &&
            filial !== null &&
            filial.value !== null
        ) {
            showAlert({
                show: true,
                content: "Aguarde um momento",
                color: "warning",
                time: 3000,
            });

            var dataPlanning =
                getLocalStorage(LOCALSTORAGE_PLANNING) ?? undefined;

            updateWaiting(true);
            await new RestUseCase(
                `${ENDPOINT_CRUD}/estoque?idFornecedor=${provider?.value}&idFilial=${filial?.value}&page=${pagination.page}&size=${pagination.size}`
            )
                .Post({
                    data: dataPlanning,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        if (response.headers["link"]) {
                            let links: LinkHeader = parseLinkHeader(
                                response.headers["link"]
                            ) as any;

                            updateFirstPagination(links.first);
                            updateLastPagination(links.last);
                            updateNextPagination(links.next);
                            updatePrevPagination(links.prev);
                            setValuePaginations(
                                Number(pagination.page),
                                parseInt(
                                    getParameterUrlByName("page", links.first)!
                                ),
                                parseInt(
                                    getParameterUrlByName("page", links.last)!
                                )
                            );
                        }

                        /** data da ultima carga */
                        if (response.headers["is-ultima-pesquisa"])
                            updateLastSearch(
                                response.headers["is-ultima-pesquisa"] ===
                                    "null"
                                    ? "NULL"
                                    : ParseDateToBrWithHours(
                                        response.headers["is-ultima-pesquisa"]
                                    )
                            );

                        updateNavigationActive("scrap");
                        updateTableHeader(
                            entityFieldsScrap.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                };
                            })
                        );

                        updateTableBody(
                            response.body.map((value: any) => {
                                value["quantidadeSalvaInput"] =
                                    value["quantidadeSalva"];
                                return value;
                            })
                        );
                        showAlert({
                            show: true,
                            content: "Busca de SCRAP realizada com sucesso",
                            color: "success",
                            time: 3000,
                        });
                    } else {
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                        updateWithError(true);
                    }
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
            await loadScrapUtilizado();
        }
    };
    const getAllHistoric = async (params?: {
        pagination: string;
        page: number;
        size: number;
    }) => {
        clearTableBody();
        if (provider !== undefined) {
            let pagePagination: number;
            let pageSize: number;
            /** configura paginacao manual ou vindo de outros metodos */
            if (params) {
                pagePagination = params.page;
                pageSize = params.size;
            } else {
                pagePagination = 0;
                pageSize = totalPageSize;
            }

            await new RestUseCase(
                `${ENDPOINT_CRUD}?idFornecedor=${provider?.value}&apenasVigente=true&page=${pagePagination}&size=${pageSize}`
            )
                .Get()
                .then((response) => {
                    if (response.statusCode === 200) {
                        if (response.headers["link"]) {
                            let links: LinkHeader = parseLinkHeader(
                                response.headers["link"]
                            ) as any;

                            updateFirstPagination(links.first);
                            updateLastPagination(links.last);
                            updateNextPagination(links.next);
                            updatePrevPagination(links.prev);
                            setValuePaginations(
                                pagePagination,
                                parseInt(
                                    getParameterUrlByName("page", links.first)!
                                ),
                                parseInt(
                                    getParameterUrlByName("page", links.last)!
                                )
                            );
                        }

                        updateNavigationActive("historico");
                        updateTableHeader(
                            entityFieldsHistoric.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                };
                            })
                        );
                        updateTableBody(response.body);
                        showAlert({
                            show: true,
                            content: "Busca de Histórico realizada com sucesso",
                            color: "success",
                            time: 3000,
                        });
                    } else {
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                        updateWithError(true);
                    }
                })
                .catch(() => updateWithError(true));
        }
    };
    const saveRow = async (event: any) => {
        const quantidadeSalvaInput = event.value === "" ? 0 : event.value;
        const item = event.item;

        await new RestUseCase(
            `${ENDPOINT_CRUD}/salvo?idFornecedor=${provider?.value}&idFilial=${filial?.value}`
        )
            .Put({
                data: [
                    { id: item.id, quantidade: Number(quantidadeSalvaInput) },
                ],
            })
            .then((response) => {
                if (
                    response.statusCode === undefined ||
                    response.statusCode < 200 ||
                    response.statusCode >= 300
                ) {
                    updateWithError(true);
                    showAlert({
                        show: true,
                        content:
                            "Ops!!! Algo deu errado tente novamente mais tarde...",
                        color: "danger",
                        time: 2000,
                    });
                } else {
                    showAlert({
                        show: true,
                        content: "Item salvo com sucesso",
                        color: "success",
                        time: 3000,
                    });
                }
            })
            .catch(() => {
                updateWithError(true);
                showAlert({
                    show: true,
                    content:
                        "Ops!!! Algo deu errado tente novamente mais tarde...",
                    color: "danger",
                    time: 2000,
                });
            });

        await loadScrapUtilizado();
    };
    const changeRow = async (event: any) => {
        const quantidadeSalvaInput = event.value;
        const item = event.item;
        clearTableBody();

        const limit = Math.min(
            item.quantidadeEstoque,
            item.quantidadeMaxima ?? Number.MAX_SAFE_INTEGER
        );
        if (limit < quantidadeSalvaInput || quantidadeSalvaInput < 0) {
            showAlert({
                show: true,
                content:
                    "Quantidade deve ser positiva e menor ou igual ao estoque/limite",
                color: "danger",
            });
            updateTableBody(
                tableBody.map((scrap) => {
                    if (event.item.id === scrap.id)
                        scrap.quantidadeSalvaInput = limit;
                    return scrap;
                })
            );
        } else {
            updateTableBody(
                tableBody.map((scrap) => {
                    if (event.item.id === scrap.id)
                        scrap.quantidadeSalvaInput = quantidadeSalvaInput;
                    return scrap;
                })
            );
        }
    };
    const sendScrap = async () => {
        showAlert({
            show: true,
            content: "Aguarde um momento",
            color: "warning",
            time: 2000,
        });
        await new RestUseCase(
            `${ENDPOINT_CRUD}?idFornecedor=${provider?.value}&idFilial=${filial?.value}&date=${dateScheduling}`
        )
            .Put()
            .then((response) => {
                if (
                    response.statusCode === undefined ||
                    response.statusCode < 200 ||
                    response.statusCode >= 300
                ) {
                    updateWithError(true);
                    showAlert({
                        show: true,
                        content:
                            "Ops!!! Algo deu errado tente novamente mais tarde...",
                        color: "danger",
                        time: 2000,
                    });
                } else {
                    showAlert({
                        show: true,
                        content: "Scrap enviado com sucesso!",
                        color: "success",
                        time: 3000,
                    });
                }
            })
            .catch(() => updateWithError(true));
    };
    const handleSelectProvider = async () => {
        await new RestUseCase(ENDPOINT_PROVIDER)
            .Get()
            .then((response) => {
                updateProviderSelect(
                    response.body.map((value: any) => {
                        return { value: value.id, label: value.nome };
                    })
                );
                if (
                    response.statusCode === undefined ||
                    response.statusCode < 200 ||
                    response.statusCode >= 300
                ) {
                    updateWithError(true);
                    showAlert({
                        show: true,
                        content:
                            "Ops!!! Algo deu errado tente novamente mais tarde...",
                        color: "danger",
                        time: 2000,
                    });
                }
            })
            .catch(() => {
                updateWithError(true);
                showAlert({
                    show: true,
                    content:
                        "Ops!!! Algo deu errado tente novamente mais tarde...",
                    color: "danger",
                    time: 2000,
                });
            });
    };
    const handleSelectFilial = async () => {
        await new RestUseCase(
            ENDPOINT_FILIAL + `?fornecedor=${provider?.value}&scrapAtivo=true`
        )
            .Get()
            .then((response) => {
                updateFilialSelect(
                    response.body.map((value: any) => {
                        return { value: value.id, label: value.nome };
                    })
                );
                updateFilialDisable(false);
            })
            .catch(() => updateWithError(true));
    };
    const getItemsHistoric = async (
        pagination: { page: string; size: string },
        idScrap: number
    ) => {
        clearTableItemBody();
        if (provider !== undefined && filial !== undefined) {
            updateWaiting(true);

            await new RestUseCase(
                `${ENDPOINT_CRUD}/${idScrap}?idScrap=${idScrap}&page=${pagination.page}&size=${pagination.size}`
            )
                .Post()
                .then((response) => {
                    if (response.statusCode === 200) {
                        updateTableItemsHeader(
                            entityFieldsItems.map((value) => {
                                return {
                                    name: value.name,
                                    labelTable: value.labelTable,
                                    align: value.align,
                                };
                            })
                        );
                        updateTableItemsBody(
                            response.body.map((value: any) => {
                                return value;
                            })
                        );
                        if (response.headers["link"]) {
                            let links: LinkHeader = parseLinkHeader(
                                response.headers["link"]
                            ) as any;

                            updateFirstPaginationModalHistory(links.first);
                            updateLastPaginationModalHistory(links.last);
                            updateNextPaginationModalHistory(links.next);
                            updatePrevPaginationModalHistory(links.prev);
                            setValuePaginationsModalHistory(
                                Number(pagination.page),
                                parseInt(getParameterUrlByName("page", links.first)!),
                                parseInt(getParameterUrlByName("page", links.last)!)
                            );
                        }
                    } else {
                        updateWithError(true);
                    }
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        }
    };
    const sendFileNfe = async () => {
        const formData = new FormData();
        formData.append("file", contentFile!);

        if(contentFile == undefined){
            showAlert({
                show: true,
                content: "Selecione um arquivo",
                color: "danger",
                time: 2000,
            });
            return;
        }

        await new RestUseCase(`${ENDPOINT_CRUD}/${rowScrap.id}/nfe`)
            .Post({
                data: formData,
            })
            .then((response) => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    showAlert({
                        show: true,
                        content: "Nota fiscal enviada com sucesso",
                        color: "success",
                        time: 2000,
                    });
                } else {
                    let message = response.error?.data[0]?.desc;
                    message = message === undefined ? "Houve um erro ao validar ou enviar NFE" : message;

                    showAlert({
                        show: true,
                        content: message,
                        color: "danger",
                        time: 5000,
                    });
                }
            })
            .catch(() => {
                showAlert({
                    show: true,
                    content: "Houve um erro ao validar ou enviar NFE",
                    color: "danger",
                    time: 5000,
                });
                updateWithError(true);
            });
        updateWaiting(false);
    };
    const sendFileCSV = async () => {
        const formData = new FormData();
        formData.append("file", contentFileCSV!);
        updateWaiting(true);
        closeModalUploadFile();

        await new RestUseCase(
            `${ENDPOINT_CRUD}/salvo?idFornecedor=${provider?.value}&idFilial=${filial?.value}`
        )
            .Put({
                data: formData,
            })
            .then((response) => {
                if (response.statusCode === 200) {
                    showAlert({
                        show: true,
                        content: "Arquivo CSV carregado com sucesso",
                        color: "success",
                        time: 5000,
                    });
                } else {
                    showAlert({
                        show: true,
                        content:
                            "Houve um erro ao validar ou enviar o arquivo CSV",
                        color: "danger",
                        time: 5000,
                    });
                    updateWithError(true);
                }
            })
            .catch(() => updateWithError(true));

        await new Promise((f) => setTimeout(f, 500));
        await getAllScrap({
            page: "0",
            size: "50",
        });
        updateWaiting(false);
    };
    const exportCSVScrap = async () => {
        if (provider !== undefined && filial !== undefined) {
            showAlert({
                show: true,
                content: "Aguarde um momento",
                color: "warning",
                time: 5000,
            });

            updateWaiting(true);

            var dataPlanning =
                getLocalStorage(LOCALSTORAGE_PLANNING) ?? undefined;

            await new RestUseCase(
                `${ENDPOINT_CRUD}/estoque?idFornecedor=${provider?.value}&idFilial=${filial?.value}`
            )
                .Post({
                    data: dataPlanning,
                    headers: { accept: "text/csv" },
                })
                .then((response) => {
                    if (response.statusCode !== 500) {
                        const url = window.URL.createObjectURL(
                            new Blob([response.body])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `Scrap - ` + provider.label + `.csv`
                        );
                        document.body.appendChild(link);
                        link.click();
                        link!.parentNode!.removeChild(link);
                        showAlert({
                            show: true,
                            content: "Exportado com sucesso",
                            color: "success",
                            time: 2000,
                        });
                    } else
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                    updateWithError(true);
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        }
    };
    const exportCSVScrapHistorico = async () => {
        if (provider !== undefined) {
            showAlert({
                show: true,
                content: "Aguarde um momento",
                color: "warning",
                time: 5000,
            });

            updateWaiting(true);
            await new RestUseCase(
                `${ENDPOINT_CRUD}?idFornecedor=${provider?.value}`
            )
                .Get({ headers: { accept: "text/csv" } })
                .then((response) => {
                    if (response.statusCode !== 500) {
                        const url = window.URL.createObjectURL(
                            new Blob([response.body])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `Scrap Histórico - ` + provider.label + `.csv`
                        );
                        document.body.appendChild(link);
                        link.click();
                        link!.parentNode!.removeChild(link);
                        showAlert({
                            show: true,
                            content: "Exportado com sucesso",
                            color: "success",
                            time: 2000,
                        });
                    } else
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        }
    };
    const exportCSVScrapHistoricoItems = async () => {
        if (provider !== undefined) {
            showAlert({
                show: true,
                content: "Aguarde um momento",
                color: "warning",
                time: 5000,
            });

            updateWaiting(true);
            await new RestUseCase(
                `${ENDPOINT_CRUD}/${rowScrap.id}`
            )
                .Get({ headers: { accept: "text/csv" } })
                .then((response) => {
                    if (response.statusCode !== 500) {
                        const url = window.URL.createObjectURL(
                            new Blob([response.body])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `Scrap Historico Itens ${rowScrap.id} - ${provider.label}.csv`
                        );
                        document.body.appendChild(link);
                        link.click();
                        link!.parentNode!.removeChild(link);
                        showAlert({
                            show: true,
                            content: "Itens exportado com sucesso",
                            color: "success",
                            time: 2000,
                        });
                    } else
                        showAlert({
                            show: true,
                            content:
                                "Ops!!! Algo deu errado tente novamente mais tarde...",
                            color: "danger",
                            time: 2000,
                        });
                })
                .catch(() => updateWithError(true));
            updateWaiting(false);
        }
    };

    const handleSubmitPlanning = async (params: any) => {
        const response = await new RestUseCase(ENDPOINT_PLANNING!).Get();
        return handleRequest(response);
    };
    const handleEditPlanning = async (params: any) => {
        const response = await new RestUseCase(
            ENDPOINT_PLANNING! + "/" + params.id
        ).Put({ data: params.model });
        return handleRequest(response);
    };

    const handleListAllPlanning = async (params: any) => {
        const response = await new RestUseCase(ENDPOINT_PLANNING!).Get();
        return handleRequest(response);
    };

    const handleCreatePlanning = async (params: any) => {
        const response = await new RestUseCase(ENDPOINT_PLANNING!).Post({
            data: params,
        });
        return handleRequest(response);
    };

    const handleRemovePlanning = async (params: any) => {
        const response = await new RestUseCase(
            ENDPOINT_PLANNING! + "/" + params
        ).Delete();
        return handleRequest(response);
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** HEADER */
    const initFilialState = {
        value: null,
        key: "",
        label: "Selecione a Filial",
        disabled: false,
        selected: false,
    };
    const [providerSelect, updateProviderSelect] =
        useState<SelectOptionInterface[]>();
    const [filialSelect, updateFilialSelect] =
        useState<SelectOptionInterface[]>();
    const [provider, updateProvider] = useState<SelectOptionInterface>();
    const [filial, updateFilial] =
        useState<SelectOptionInterface>(initFilialState);
    const [filialDisable, updateFilialDisable] = useState<boolean>(true);
    const [dateScheduling, updateDateScheduling] = useState<string>();
    const onChangeSelectProvider = (
        providerOptionValue: SelectOptionInterface
    ) => {
        updateProvider(providerOptionValue);
    };
    const onChangeSelectFilial = (filialOptionValue: SelectOptionInterface) => {
        updateFilial(filialOptionValue);
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** PAINEL */
    const [painelInfo, updatePanelInfo] = useState<any>({});
    const [valorScrapUtilizado, setScrapValorUtilizado] = useState<number>(0);
    const [valorScrapMaximo, setScrapValorMaximo] = useState<number>(0);
    const [dataFimVigenciaScrap, setDataFimVigenciaScrap] =
        useState<string>("");
    const [dataInicioVigenciaScrap, setDataInicioVigenciaScrap] =
        useState<string>("");

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** PAGINACAO */
    var totalPageSize: number = 50;
    const [firstPagination, updateFirstPagination] = useState<string>("");
    const [lastPagination, updateLastPagination] = useState<string>("");
    const [nextPagination, updateNextPagination] = useState<string>("");
    const [prevPagination, updatePrevPagination] = useState<string>("");
    const [paginationItemList, updatePaginationItemList] =
        useState<{ item: number; active: boolean }[]>();
    const setValuePaginations = (
        paginationsActive: number,
        prev: number,
        next: number
    ) => {
        let modelPaginationList: any[] = [];
        for (
            let index = paginationsActive;
            index < 6 + paginationsActive;
            index++
        ) {
            if (!(index > next))
                modelPaginationList.push({
                    item: index,
                    active: index === paginationsActive ? true : false,
                });
        }
        updatePaginationItemList(modelPaginationList);
    };
    const onClickItemPagination = async (pagination: any) => {
        await getAllScrap({
            page: pagination.item,
            size: totalPageSize.toString(),
        });
    };
    const onClickFirstPagination = async () => {
        if (navigationActive === "scrap") {
            const page: string = getParameterUrlByName(
                "page",
                firstPagination
            )!;
            const size: string = getParameterUrlByName(
                "size",
                firstPagination
            )!;
            await getAllScrap({ page: page, size: size });
        } else {
            const page: string = getParameterUrlByName(
                "page",
                firstPagination
            )!;
            await getAllHistoric({
                pagination: `?page=${page}&size=${totalPageSize}`,
                page: Number(page),
                size: totalPageSize,
            });
        }
    };
    const onClickLastPagination = async () => {
        if (navigationActive === "scrap") {
            const page: string = getParameterUrlByName("page", lastPagination)!;
            const size: string = getParameterUrlByName("size", lastPagination)!;
            await getAllScrap({ page: page, size: size });
        } else {
            const page: string = getParameterUrlByName("page", lastPagination)!;
            await getAllHistoric({
                pagination: `?page=${page}&size=${totalPageSize}`,
                page: Number(page),
                size: totalPageSize,
            });
        }
    };
    const onClickNextPagination = async () => {
        if (navigationActive === "scrap") {
            const page: string = getParameterUrlByName("page", nextPagination)!;
            const size: string = getParameterUrlByName("size", nextPagination)!;
            await getAllScrap({ page: page, size: size });
        } else {
            const page: string = getParameterUrlByName("page", nextPagination)!;
            await getAllHistoric({
                pagination: `?page=${page}&size=${totalPageSize}`,
                page: Number(page),
                size: totalPageSize,
            });
        }
    };
    const onClickPrevPagination = async () => {
        if (navigationActive === "scrap") {
            const page: string = getParameterUrlByName("page", prevPagination)!;
            const size: string = getParameterUrlByName("size", prevPagination)!;
            await getAllScrap({ page: page, size: size });
        } else {
            const page: string = getParameterUrlByName("page", prevPagination)!;
            await getAllHistoric({
                pagination: `?page=${page}&size=${totalPageSize}`,
                page: Number(page),
                size: totalPageSize,
            });
        }
    };

    /** PAGINACAO MODAL HISTORY */
    var totalPageSizeModalHistory: number = 50;
    const [firstPaginationModalHistory, updateFirstPaginationModalHistory] = useState<string>("");
    const [lastPaginationModalHistory, updateLastPaginationModalHistory] = useState<string>("");
    const [nextPaginationModalHistory, updateNextPaginationModalHistory] = useState<string>("");
    const [prevPaginationModalHistory, updatePrevPaginationModalHistory] = useState<string>("");
    const [paginationItemListModalHistory, updatePaginationItemListModalHistory] =
        useState<{ item: number; active: boolean }[]>();
    const setValuePaginationsModalHistory = (
        paginationsActive: number,
        prev: number,
        next: number
    ) => {
        let modelPaginationList: any[] = [];
        for (
            let index = paginationsActive;
            index < 6 + paginationsActive;
            index++
        ) {
            if (!(index > next))
                modelPaginationList.push({
                    item: index,
                    active: index === paginationsActive ? true : false,
                });
        }
        updatePaginationItemListModalHistory(modelPaginationList);
    };
    const onClickItemPaginationModalHistory = async (pagination: any) => {
        await getItemsHistoric({
            page: pagination.item,
            size: totalPageSizeModalHistory.toString(),
        }, rowScrap.id);
    };
    const onClickFirstPaginationModalHistory = async () => {
        const page: string = getParameterUrlByName("page", firstPaginationModalHistory)!;
        const size: string = getParameterUrlByName("size", firstPaginationModalHistory)!;
        await getItemsHistoric({ page: page, size: size }, rowScrap.id);

    };
    const onClickLastPaginationModalHistory = async () => {
        const page: string = getParameterUrlByName("page", lastPaginationModalHistory)!;
        const size: string = getParameterUrlByName("size", lastPaginationModalHistory)!;
        await getItemsHistoric({ page: page, size: size }, rowScrap.id);
    };
    const onClickNextPaginationModalHistory = async () => {
        const page: string = getParameterUrlByName("page", nextPaginationModalHistory)!;
        const size: string = getParameterUrlByName("size", nextPaginationModalHistory)!;
        await getItemsHistoric({ page: page, size: size }, rowScrap.id);
    };
    const onClickPrevPaginationModalHistory = async () => {
        const page: string = getParameterUrlByName("page", prevPaginationModalHistory)!;
        const size: string = getParameterUrlByName("size", prevPaginationModalHistory)!;
        await getItemsHistoric({ page: page, size: size }, rowScrap.id);
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** MODAL ITEMS */
    const [showModalItem, updateShowModalItem] = useState<boolean>(false);
    const [rowScrap, updateRowScrap] = useState<any>();
    const [contentFile, updateContentFile] = useState<File | undefined>();
    const openModalItem = (params: any) => {
        updateShowModalItem(true);
        updateRowScrap(params);
        getItemsHistoric({ page: "0", size: "50" }, params.id);
    };
    const closeModalItem = () => {
        updateShowModalItem(false);
        changeNavigationActive("historico");
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** MODAL UPLOAD FILE */
    const [showModalUploadFile, updateShowUploadFile] =
        useState<boolean>(false);
    const [contentFileCSV, updateContentFileSCV] = useState<File | undefined>();
    const openModalUploadFile = () => {
        if (filial !== undefined && filial !== null && filial.value !== null) {
            updateShowUploadFile(true);
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            showAlert({
                show: true,
                content: "Selecione a Filial para carregar um arquivo",
                color: "warning",
                time: 6000,
            });
        }
    };
    const closeModalUploadFile = () => {
        updateShowUploadFile(false);
        changeNavigationActive("historico");
    };

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** INICIO */
    useEffect(() => {
        handleSelectProvider();
        handleLastCharge();
        listLocations();
    }, []);

    /** ATUALIZA INFORMAÇÃO DO PAINEL */
    useEffect(() => {
        if (filial == null || filial.value == null || provider == null) return;

        const loadPainelInfo = async (): Promise<any> => {
            await new RestUseCase(
                `${ENDPOINT_CURRENT_FORNECEDOR}?idFornecedor=${provider.value}&idFilial=${filial.value}`
            )
                .Get()
                .then((response) => {
                    setScrapValorMaximo(Number(response.body.valorMaximoScrap ?? 0));
                    setDataFimVigenciaScrap(response.body.fimVigencia);
                    setDataInicioVigenciaScrap(response.body.inicioVigencia);
                });
        };

        loadPainelInfo().catch(() => {
            showAlert({
                show: true,
                content: "Erro ao carregar dados do scrap",
                color: "danger",
                time: 2000,
            });
        });
    }, [filial, provider]);

    useEffect(() => {
        const currencyFormatter = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
        updatePanelInfo([
            [
                {
                    label: "Valor scrap máximo",
                    number: currencyFormatter.format(valorScrapMaximo),
                },
                {
                    label: "Valor scrap utilizado",
                    number: currencyFormatter.format(valorScrapUtilizado),
                },
                {
                    label: "Valor scrap disponível",
                    number: currencyFormatter.format(
                        valorScrapMaximo - valorScrapUtilizado
                    ),
                },
            ],
        ]);
    }, [valorScrapUtilizado, valorScrapMaximo, dataFimVigenciaScrap]);

    /** VERIFICA PERMISSAO DE ACESSO */
    const { loggedUserData } = UseAuth();
    const [showScreen, updateShowScreen] = useState<boolean>(false);
    useEffect(() => {
        const roleScreen = HeaderNavegationList.filter(
            (header) => header.navTitle === "Scrap"
        )[0].navItem.filter((item) => item.title === "Gerar Scrap")[0].role;

        if (loggedUserData?.role) {
            loggedUserData.role.forEach((value) => {
                if (roleScreen.includes(value)) updateShowScreen(true);
            });
        }
    }, [loggedUserData]);

    const [filialPlanning, updateFilialPlanning] = useState<SelectOptionInterface[]>();
    const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>();
    const [base, updateBase] = useState<SelectOptionInterface[]>();
    const [manufacturer, updateManufacturer] = useState<SelectOptionInterface[]>();
    const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>();
    const [planningList, updatePlanningList] = useState<any[]>([]);
    const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv("REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS")!;
    const END_POINT_ITEM_TRACKS = getEnv("REACT_APP_END_POINT_ITEM_TRACKS")!;
    const END_POINT_ITEM_BASE = getEnv("REACT_APP_END_POINT_ITEM_BASE")!;

    const handleFilial = async () => {
        await new RestUseCase(ENDPOINT_FILIAL).Get().then((response) => {
            updateFilialPlanning(
                response.body.map((value: any) => {
                    return { value: value.id, label: value.nome };
                })
            );
        });
    };

    const handleDiscountGroup = async () => {
        await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS)
            .Get()
            .then((response) => {
                updateDiscountGroup(
                    response.body.map((value: any) => {
                        return { value: value, label: value };
                    })
                );
            });
    };
    const handleRangeProfitability = async () => {
        await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
            updateRangeProfitability(
                response.body.map((value: any) => {
                    return { value: value, label: value };
                })
            );
        });
    };
    const handleBase = async () => {
        await new RestUseCase(END_POINT_ITEM_BASE).Get().then((response) => {
            updateBase(
                response.body.bases.map((value: any) => {
                    return { value: value.codigo, label: `${value.codigo} - ${value.desc}` };
                })
            );
        });
    };
    const handleManufacturer = async () => {
        await new RestUseCase(ENDPOINT_MANUFACTURER).Get().then((response) => {
            updateManufacturer(
                response.body.map((value: any) => {
                    return {
                        value: value.codigo,
                        label: `${value.codigo} - ${value.nome}`,
                    };
                })
            );
        });
    };
    const [baseDesc, updateBaseDesc] = useState<SelectOptionInterface[]>();
    const END_POINT_ITEM_BASE_DESC = getEnv('REACT_APP_END_POINT_ITEM_BASE_DESC')!;
    const handleBaseDesc = async () => {
        await new RestUseCase(END_POINT_ITEM_BASE_DESC).Get().then((response) => {
            updateBaseDesc(
                response.body.descricoes.map((value: any) => {
                    console.log(value);
                    return {
                        value: value,
                        label: value,
                    };
                }),
            );
        });
    };
    useEffect(() => {
        handleSelectProvider();

        handleFilial();
        handleDiscountGroup();
        handleRangeProfitability();
        handleBase();
        handleManufacturer();
        handleBaseDesc();

        const planningLocalStorage = getLocalStorage(LOCALSTORAGE_PLANNING);

        if (planningLocalStorage)
            updateItemPlanning(planningLocalStorage.length - 1);
    }, []);

    useEffect(() => {
        if (provider) {
            if (navigationActive === "historico") {
                getAllHistoric({
                    pagination: `?page=${0}&size=${totalPageSize}`,
                    page: 0,
                    size: totalPageSize,
                });
            }
            handleSelectFilial();
            updateFilialSelect([]);
            updateFilial(initFilialState);
        }
    }, [provider]);

    useEffect(() => {
        if (filial) {
            if (navigationActive === "scrap") {
                getAllScrap({
                    page: "0",
                    size: "50",
                });
            }
        }
    }, [filial]);

    useEffect(() => {
        ScrapFilterPlanningAdapter.map((value) => {
            if (value.label === "Filial") {
                value.field[1].optionsInput = filialPlanning;
            }

            if (value.label === "Grupo de desconto") {
                value.field[1].optionsInput = discountGroup;
            }

            if (value.label === "Base") {
                value.field[1].optionsInput = base;
            }
            if (value.label === 'Base Descrição') {
                value.field[1].optionsInput = baseDesc;
            }
            if (value.label === "Lucratividade do fornecedor") {
                value.field[1].optionsInput = rangeProfitability;
            }

            if (value.label === "Código do Fabricante") {
                value.field[1].optionsInput = manufacturer;
            }

            if (value.label === 'Locações') value.field[1].optionsInput = locationList;
        });
        var filteredPlanningList: any[] = ScrapFilterPlanningAdapter.filter(value => {
            if (value.label !== "Status do item do Fornecedor") {
                return value
            }
        })
        filteredPlanningList.sort(function (a, b) {
            return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
        });
        updatePlanningList(filteredPlanningList)
    }, [
        filialPlanning,
        discountGroup,
        base,
        baseDesc,
        rangeProfitability,
        locationList
    ]);

    /**
     *
     *
     *
     *
     *
     *
     *
     *
     */
    /** RENDERIZACAO */
    return (
        <>
            {showScreen ? (
                <ReportPage
                    titlePage={titlePage}
                    entityFields={entityFields}
                    optionsFiltersDefault={planningList}
                    tableHeader={tableHeader}
                    changeItem={changeRow}
                    tableBody={tableBody}
                    configTable={configTable}
                    isOpenModalPlanning={showModalPlanning}
                    showModalPlanning={showModalPlanning}
                    openModalPlanning={openModalPlanning}
                    closeModalPlanning={closeModalPlanning}
                    onClickFirstPagination={onClickFirstPagination}
                    onClickLastPagination={onClickLastPagination}
                    onClickNextPagination={onClickNextPagination}
                    onClickPrevPagination={onClickPrevPagination}
                    pageDescriptionPagination={""}
                    onClickItemPagination={onClickItemPagination}
                    paginationItemList={paginationItemList}
                    saveRow={saveRow}
                    withError={withError}
                    waiting={waiting}
                    isOpenModalItem={openModalItem}
                    keyLocalStoragePlanning={LOCALSTORAGE_PLANNING}
                    handleApplyPlanning={handleApplyPlanning}
                    handleSubmitPlanning={handleSubmitPlanning}
                    handleEditPlanning={handleEditPlanning}
                    handleListAllPlanning={handleListAllPlanning}
                    handleCreatePlanning={handleCreatePlanning}
                    handleRemovePlanning={handleRemovePlanning}
                >
                    <>
                        <div>
                            <Button
                                variant="outline-primary"
                                className="d-flex align-items-center"
                                onClick={openModalPlanning}
                                size="sm"
                                disabled={provider ? false : true}
                            >
                                {itemPlanning > 0 ? (
                                    <Badge bg="danger" className="me-2">
                                        {itemPlanning}
                                    </Badge>
                                ) : (
                                    <></>
                                )}
                                <BsSliders className="me-1" />
                                Planejamento
                            </Button>
                        </div>
                    </>
                    <>
                        <div className="d-flex justify-content-between align-items-end">
                            <div className="d-flex justify-content-start align-items-end">
                                <div style={{ width: 300 }}>
                                    <Form.Label>Fornecedor:</Form.Label>
                                    <Select
                                        defaultValue={provider}
                                        options={providerSelect}
                                        onChange={(val) => {
                                            onChangeSelectProvider(val!);
                                        }}
                                        placeholder="Selecione o Fornecedor"
                                    />
                                </div>
                                <div
                                    className="mx-1"
                                    style={{ width: 300, zIndex: 2 }}
                                >
                                    <Form.Label>Filial:</Form.Label>
                                    <Select
                                        value={filial}
                                        options={filialSelect}
                                        onChange={(val) =>
                                            onChangeSelectFilial(val!)
                                        }
                                        isDisabled={filialDisable}
                                        placeholder="Selecione a Filial"
                                    />
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        onClick={async () => {
                                            if (navigationActive === "scrap") {
                                                await getAllScrap({
                                                    page: "0",
                                                    size: "50",
                                                });
                                            }

                                            if (
                                                navigationActive === "historico"
                                            ) {
                                                await getAllHistoric({
                                                    pagination: `?page=${0}&size=${totalPageSize}`,
                                                    page: 0,
                                                    size: totalPageSize,
                                                });
                                            }
                                        }}
                                    >
                                        Atualizar
                                    </Button>
                                </div>
                            </div>
                            <div>
                                {navigationActive === "scrap" ? (
                                    <>
                                        <div className="d-flex justify-content-start">
                                            <div className="mx-1">
                                                <Button
                                                    variant="primary"
                                                    onClick={
                                                        openModalUploadFile
                                                    }
                                                >
                                                    Carregar Scrap CSV
                                                </Button>
                                            </div>
                                            <div className="mx-1">
                                                <Button
                                                    variant="primary"
                                                    disabled={
                                                        provider ===
                                                        undefined ||
                                                        filial.value === null
                                                    }
                                                    onClick={exportCSVScrap}
                                                >
                                                    Exportar Scrap CSV
                                                </Button>
                                            </div>
                                            <div>
                                                <InputGroup
                                                    style={{ minWidth: 250 }}
                                                >
                                                    <Button
                                                        disabled={
                                                            dateScheduling ===
                                                            undefined
                                                        }
                                                        onClick={() =>
                                                            showTermo("Enviar")
                                                        }
                                                    >
                                                        Enviar para fábrica
                                                    </Button>
                                                    <Form.Control
                                                        type="date"
                                                        onChange={(e) =>
                                                            updateDateScheduling(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </InputGroup>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="d-flex justify-content-start">
                                            <div className="mx-1">
                                                <Button
                                                    variant="primary"
                                                    disabled={
                                                        provider === undefined
                                                    }
                                                    onClick={
                                                        exportCSVScrapHistorico
                                                    }
                                                >
                                                    Exportar Histórico Scrap CSV
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        {navigationActive === "scrap" &&
                            filial.value !== null &&
                            provider !== undefined ? (
                            <>
                                <Container className="d-flex align-items-center justify-content-center my-4">
                                    <Painel panel={painelInfo} controls={false} />
                                </Container>
                                <div className="mt-3">
                                    <Row>
                                        <Col className="d-flex align-items-center justify-content-end">
                                            <div>
                                                <Badge bg="secondary">
                                                    {lastSearch &&
                                                        dataFimVigenciaScrap !==
                                                        "NULL"
                                                        ? `Inicio vigência scrap: ${ParseDateToBrV2(
                                                            dataInicioVigenciaScrap
                                                        )}`
                                                        : `Sem dados do Inicio vigência scrap`}
                                                </Badge>
                                                <Badge
                                                    bg="secondary"
                                                    className="ms-2"
                                                >
                                                    {lastSearch &&
                                                        dataFimVigenciaScrap !==
                                                        "NULL"
                                                        ? `Fim vigência scrap: ${ParseDateToBrV2(
                                                            dataFimVigenciaScrap
                                                        )}`
                                                        : `Sem dados do Fim vigência scrap`}
                                                </Badge>
                                                <Badge
                                                    bg="secondary"
                                                    className="ms-2"
                                                >
                                                    {lastSearch &&
                                                        lastSearch !== "NULL"
                                                        ? `Última Pesquisa: ${lastSearch}`
                                                        : `Sem dados da última pesquisa`}
                                                </Badge>
                                                <Badge
                                                    bg="secondary"
                                                    className="ms-2"
                                                >
                                                    {lastCharge &&
                                                        lastCharge !== "NULL"
                                                        ? `Última Carga: ${lastCharge}`
                                                        : `Sem dados da última carga`}
                                                </Badge>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                    <>
                        <div>
                            <Nav
                                className="justify-content-center my-3"
                                variant="tabs"
                                defaultActiveKey={navigationActive}
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="scrap"
                                        onClick={() =>
                                            changeNavigationActive("scrap")
                                        }
                                    >
                                        Scrap
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="historico"
                                        onClick={() =>
                                            changeNavigationActive("historico")
                                        }
                                    >
                                        Histórico
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </>
                    <></>
                    <>
                        <ModalListItemGenerateScrap
                            isOpen={showModalItem}
                            title={"Items Scrap"}
                            open={openModalItem}
                            close={closeModalItem}
                            children={{
                                body: (
                                    <>
                                        {rowScrap != null &&
                                            rowScrap.status ===
                                            "Aguardando Concessionária inserir NF (XML)" ? (
                                            <>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Label>
                                                            Arquivo NF (XML):
                                                        </Form.Label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                event.preventDefault();
                                                                updateContentFile(
                                                                    event.target
                                                                        .files![0]
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={6} style={{ alignSelf: "last baseline" }} >
                                                        <div className="d-flex align-items-center justify-content-end mt-2" >
                                                            <Button
                                                                variant="primary"
                                                                size="sm"
                                                                onClick={sendFileNfe} >
                                                                Enviar Arquivo NF (XML)
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <Row>
                                            <div className="d-flex align-items-center justify-content-end mt-2">
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    onClick={exportCSVScrapHistoricoItems}
                                                >
                                                    Exportar Histórico CSV
                                                </Button>
                                            </div>
                                        </Row>
                                        <div className="mt-3">
                                            <CreateModalReport
                                                tableHeader={tableItemsHeader}
                                                tableBody={tableItemsBody}
                                                entityFields={entityFieldsItems}
                                                configTable={configTableItems}
                                                onClickFirstPagination={onClickFirstPaginationModalHistory}
                                                onClickLastPagination={onClickLastPaginationModalHistory}
                                                onClickNextPagination={onClickNextPaginationModalHistory}
                                                onClickPrevPagination={onClickPrevPaginationModalHistory}
                                                pageDescriptionPagination={""}
                                                onClickItemPagination={onClickItemPaginationModalHistory}
                                                paginationItemList={paginationItemListModalHistory}

                                            />
                                        </div>
                                    </>
                                ),
                                footer: undefined,
                            }}
                        />
                        <ModalUploadFile
                            isOpen={showModalUploadFile}
                            title={"Upload de Arquivo CSV"}
                            children={{
                                body: (
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Label>
                                                    Arquivo CSV:
                                                </Form.Label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={(event) => {
                                                        event.preventDefault();
                                                        updateContentFileSCV(
                                                            event.target
                                                                .files![0]
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                ),
                                footer: (
                                    <>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={sendFileCSV}
                                        >
                                            Enviar Arquivo CSV
                                        </Button>
                                    </>
                                ),
                            }}
                            open={openModalUploadFile}
                            close={closeModalUploadFile}
                        />
                        <ModalTermo />
                    </>
                </ReportPage>
            ) : (
                <ErrorNotAuthorizedPage />
            )}
        </>
    );
};
