import { ColumnsActiveCampaignAdapter } from "helper/adapter/columsTable/columns-table-active-campaign-adapter";
import { SelectOptionInterface } from "../../../@types/entity/interface/select-option-interface";
import { getLocalStorage, updateLocalStorege } from "data/cache/localstorage-cache";
import { HeaderNavegationList } from "helper/adapter/headerNavigation/header-navegation-adapter";
import { ActiveCampaignsPlanningAdapter } from "helper/adapter/planning/active-campaigns-planning-adapter";
import { showErrorAlert, showWaitAlert, showSuccessAlert } from "helper/show-alert-helper";
import { checkColumns } from "helper/table-columns-helper";
import { getEnv } from "helper/window-helper";
import { UseAlert } from "hook/alert-hook";
import { UseAuth } from "hook/auth-hook";
import { ActiveCampaignsPage } from "pages/layout/analysis/active-campaigns-page"
import { useEffect, useState } from "react";
import { handleRequest } from "service/api/request-common";
import { RestCall } from "service/api/rest/rest-call";
import { RestUseCase } from "service/api/rest/rest-usecase";
import ErrorNotAuthorizedPage from "pages/layout/error/error-not-authorized-page";

export const ActiveCampaignsFactory: React.FC = () => {

  const LOCALSTORAGE_COLUMS = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ACTIVE_CAMPAIGN')!;
  const LOCALSTORAGE_PLANNING = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_ACTIVE_CAMPAIGN')!;
  const LOCALSTORAGE_PLANNING_ANALYTICAL = getEnv('REACT_APP_LOCALSTORAGE_PLANNING_ANALYTICAL')!;
  const ENDPOINT_ACTIVE_CAMPAIGNS = getEnv('REACT_APP_END_POINT_ACTIVE_CAMPAIGN');
  const LOCALSTORAGE_COLUMS_ORDER = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ACTIVE_CAMPAIGN')! + '_order';
  const LOCALSTORAGE_COLUMS_ORDER_ANALITYCAL = getEnv('REACT_APP_LOCALSTORAGE_COLUMS_ANALYTICAL')! + '_order';
  const ENDPOINT_FILIAL = getEnv("REACT_APP_END_POINT_FILIAL")!;
  const ENDPOINT_PLANNING = getEnv('REACT_APP_END_POINT_ACTIVE_CAMPAIGN') + '/filtros';
  const END_POINT_ITEM = getEnv('REACT_APP_END_POINT_ITEM');
  const END_POINT_MOVEMENT = getEnv('REACT_APP_END_POINT_MOVEMENT');
  const END_POINT_CORRELATE = getEnv('REACT_APP_END_POINT_CORRELATE_ICON')!;
  const END_POINT_ITEM_BASE_DESC = getEnv('REACT_APP_END_POINT_ITEM_BASE_DESC')!;
  const END_POINT_ITEM_BASE_CODE = getEnv('REACT_APP_END_POINT_ITEM_BASE_CODE')!;
  const ENDPOINT_COMPANIES_OBSOLETO = getEnv('REACT_APP_END_POINT_COMPANIES_OBSOLETO');
  const ENDPOINT_MANUFACTURER = getEnv('REACT_APP_END_POINT_MANUFACTURER')!;
  const ENDPOINT_LOCATIONS = getEnv('REACT_APP_END_POINT_LOCATIONS');
  const END_POINT_ORIGINAL_ITEMS_TRACKS = getEnv('REACT_APP_END_POINT_ORIGINAL_ITEMS_TRACKS')!;
  const END_POINT_ITEM_TRACKS = getEnv('REACT_APP_END_POINT_ITEM_TRACKS')!;
  const ENDPOINT_ANALYTICAL = getEnv('REACT_APP_END_POINT_ANALYTICAL')!;

  const { showAlert } = UseAlert();
  const { loggedUserData } = UseAuth();

  const [isLoading, updateLoading] = useState<boolean>(false);
  const [showScreen, updateShowScreen] = useState<boolean>(false);
  const [branchOptions, updateBranchOptions] = useState<SelectOptionInterface[]>();
  const [campaignsOptions, updateCampaignsOptions] = useState<SelectOptionInterface[]>();

  const [companyObsoleto, updateCompanyObsoleto] = useState<SelectOptionInterface[]>();
  const [discountGroup, updateDiscountGroup] = useState<SelectOptionInterface[]>();
  const [baseDesc, updateBaseDesc] = useState<SelectOptionInterface[]>();
  const [baseCode, updateBaseCode] = useState<SelectOptionInterface[]>();
  const [rangeProfitability, updateRangeProfitability] = useState<SelectOptionInterface[]>();
  const [manufacturer, updateManufacturer] = useState<SelectOptionInterface[]>();
  const [locationList, updateLocationList] = useState<SelectOptionInterface[]>([]);


  useEffect(() => {
    findBranchs()
    checkColumns(LOCALSTORAGE_COLUMS, ColumnsActiveCampaignAdapter);
    handleDiscountGroup();
    handleRangeProfitability();
    handleBaseDesc();
    handleBaseCode();
    handleManufacturer();
    handleCompanyObsoleto();
    listLocations();
  }, [])

  useEffect(() => {
    const roleScreen = HeaderNavegationList.filter((header) => header.navTitle === 'Análise')[0].navItem.filter((item) => item.title === 'Analítico')[0].role;

    if (loggedUserData?.role) {
      loggedUserData.role.forEach((value) => {
        if (roleScreen.includes(value)) updateShowScreen(true);
      });
    }
  }, [loggedUserData]);

  useEffect(() => {
    ActiveCampaignsPlanningAdapter.map((value) => {
      if (value.label === 'Grupo de desconto') value.field[1].optionsInput = discountGroup;
      if (value.label === 'Base') {
        value.field[1].optionsInput = baseCode;
      }
      if (value.label === 'Base Descrição') {
        value.field[1].optionsInput = baseDesc;
      }
      if (value.label === 'Lucratividade do fornecedor') value.field[1].optionsInput = rangeProfitability;
      if (value.label === 'Obsoleto') {
        value.field[1].optionsInput = companyObsoleto;
      }
      if (value.label === 'Código do Fabricante') value.field[1].optionsInput = manufacturer;
      if (value.label === 'Locações') value.field[1].optionsInput = locationList;
    });

    ActiveCampaignsPlanningAdapter.sort(function (a, b) {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
    });
  }, [discountGroup, rangeProfitability, companyObsoleto, manufacturer, baseDesc, baseCode, locationList]);

  const handleCompanyObsoleto = async () => {
    await new RestUseCase(ENDPOINT_COMPANIES_OBSOLETO).Get().then((response) => {
      updateCompanyObsoleto(
        response.body.map((value: any) => {
          return { value: value.id, label: value.nome };
        }),
      );
    });
  };
  const handleDiscountGroup = async () => {
    await new RestUseCase(END_POINT_ORIGINAL_ITEMS_TRACKS).Get().then((response) => {
      updateDiscountGroup(
        response.body.map((value: any) => {
          return { value: value, label: value };
        }),
      );
    });
  };
  const handleRangeProfitability = async () => {
    await new RestUseCase(END_POINT_ITEM_TRACKS).Get().then((response) => {
      updateRangeProfitability(
        response.body.map((value: any) => {
          return { value: value, label: value };
        }),
      );
    });
  };
  const handleBaseDesc = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE_DESC).Get().then((response) => {
      updateBaseDesc(
        response.body.descricoes.map((value: any) => {
          return {
            value: value,
            label: value,
          };
        }),
      );
    });
  };

  const handleBaseCode = async () => {
    await new RestUseCase(END_POINT_ITEM_BASE_CODE).Get().then((response) => {
      updateBaseCode(
        response.body.codigos.map((value: any) => {
          return {
            value: value,
            label: value,
          };
        }),
      );
    });
  };

  const handleManufacturer = async () => {
    await new RestUseCase(ENDPOINT_MANUFACTURER).Get().then((response) => {
      updateManufacturer(
        response.body.map((value: any) => {
          return {
            value: value.codigo,
            label: `${value.codigo} - ${value.nome}`,
          };
        }),
      );
    });
  };

  async function listLocations(): Promise<any> {
    await new RestUseCase(ENDPOINT_LOCATIONS).Get().then((response) => {
      if (response.statusCode !== 500 && response.statusCode !== undefined) {
        updateLocationList(
          response.body.locacoes.map((value: any) => {
            return { value: value.codigo, label: value.codigo };
          }),
        );
      }
    });
  }

  const findBranchs = async () => {
    await new RestUseCase(ENDPOINT_FILIAL)
      .Get()
      .then((response) => {
        updateBranchOptions(
          response.body.map((value: any) => {
            return { value: value.id, label: value.nome };
          })
        );
      })
  };

  const findCampaigns = async (idBranch: number) => {
    await new RestCall(ENDPOINT_ACTIVE_CAMPAIGNS! + "/listar/" + idBranch)
      .Get()
      .then((response) => {
        updateCampaignsOptions(
          response.data.map((value: any) => {
            return { value: value.id, label: value.descricao };
          })
        );
      })
  };

  async function listAnalitycal(params: { pagination: string; model?: any }): Promise<any> {
    showWaitAlert(showAlert, "Aguarde um momento")
    updateLoading(true);

    let localStorageColumsOrder: any[] = getLocalStorage(LOCALSTORAGE_COLUMS_ORDER_ANALITYCAL) ?? [];

    let queryParamsOrder = '';

    localStorageColumsOrder.forEach((fe) => {
      if (fe.ordinationValue) {
        queryParamsOrder += `&sort=${fe.ordinationName.toLowerCase()}:${fe.ordinationValue}`;
      }
    });

    var dataPlanning = params.model ? params.model : getLocalStorage(LOCALSTORAGE_PLANNING_ANALYTICAL);

    const response = await new RestUseCase(ENDPOINT_ANALYTICAL! + params?.pagination + queryParamsOrder).Post({
      data: dataPlanning ? dataPlanning : undefined,
    }).then((response) => {
      if (response.statusCode !== 200) {
        showErrorAlert(showAlert, "Erro na busca de dados")
        updateLoading(false);
        return response
      } else {
        updateLoading(false);
        return response
      }
    });;

    if (response.statusCode !== 200) {
      updateLocalStorege(LOCALSTORAGE_PLANNING, []);
    }

    return handleRequest(response);
  }

  async function list(params: { pagination: string; model?: any; idBranch?: number; idCampaign?: number }): Promise<any> {
    showWaitAlert(showAlert, "Aguarde um momento")
    updateLoading(true);

    let localStorageColumsOrder: any[] = getLocalStorage(LOCALSTORAGE_COLUMS_ORDER) ?? [];
    let queryParamsOrder = '';

    localStorageColumsOrder.forEach((fe) => {
      if (fe.ordinationValue) {
        queryParamsOrder += `&sort=${fe.ordinationName.toLowerCase()}:${fe.ordinationValue}`;
      }
    });

    let filtersPlanning: any[] = []
    if (params.idBranch !== undefined && params.idCampaign !== undefined) {
      filtersPlanning = [
        {
          "type": "FILIAL",
          "params": {
            "type": "IN",
            "filiais": [
              params.idBranch
            ]
          }
        },
        {
          "type": "CAMPANHA",
          "params": {
            "type": "EQUALS",
            "campanha":
              params.idCampaign
          }
        }
      ]
    }
    let dataPlanning: any[] = params.model ? params.model : getLocalStorage(LOCALSTORAGE_PLANNING);
    if (dataPlanning === null) {
      dataPlanning = filtersPlanning
    } else {
      filtersPlanning.map(value => dataPlanning.push(value))
    }

    return await new RestCall(ENDPOINT_ACTIVE_CAMPAIGNS! + "/listar" + params?.pagination + queryParamsOrder).Post({
      data: dataPlanning ? dataPlanning : undefined,
    }).then((response) => {
      if (response.success) {
        updateLoading(false);
        return response
      } else {
        showErrorAlert(showAlert, response.message)
        updateLoading(false);
        return response
      }
    });
  }

  async function getActiveCampaignsTotals(idIntencao: number, idBranch: number, idCampaign: number): Promise<any> {
    updateLoading(true);

    let filtersPlanning: any[] = []
    if (idBranch !== undefined && idCampaign !== undefined) {
      filtersPlanning = [...filtersPlanning,
      {
        "type": "FILIAL",
        "params": {
          "type": "IN",
          "filiais": [
            idBranch
          ]
        }
      },
      {
        "type": "CAMPANHA",
        "params": {
          "type": "EQUALS",
          "campanha":
            idCampaign
        }
      }
      ]
    }

    return await new RestCall(ENDPOINT_ACTIVE_CAMPAIGNS! + "/listar/totais/" + idIntencao).Post({
      data: filtersPlanning,
    }).then((response) => {
      if (response.success) {
        showSuccessAlert(showAlert, response.message)
        updateLoading(false);
        return response
      } else {
        showErrorAlert(showAlert, response.message)
        updateLoading(false);
        return response
      }
    });
  }

  async function save(params: { idIntencao: number, item: any[] }): Promise<any> {
    updateLoading(true);
    await new RestCall(ENDPOINT_ACTIVE_CAMPAIGNS! + "/item-intencao-compra/" + params.idIntencao).Post({ data: params.item })
      .then((response) => {
        if (response.success) {
          updateLoading(false);
          showSuccessAlert(showAlert, response.message)
        } else {
          showErrorAlert(showAlert, response.message)
          updateLoading(false);
        }
      })

  }

  async function removePendingQuantities(idIntencao: number): Promise<any> {
    updateLoading(true);
    return await new RestCall(ENDPOINT_ACTIVE_CAMPAIGNS! + "/item-intencao-compra/" + idIntencao).Delete()
      .then((response) => {
        if (response.success) {
          showSuccessAlert(showAlert, response.message)
          updateLoading(false);
          return response
        } else {
          showErrorAlert(showAlert, response.message)
          updateLoading(false);
          return response
        }
      });
  }

  async function sendOrder(idIntencao: number, idBranch: number, idCampaign: number): Promise<any> {
    updateLoading(true);
    await new RestCall(ENDPOINT_ACTIVE_CAMPAIGNS! + "/intencao-compra").Post({
      data: {
        idIntencaoCompra: idIntencao,
        idFilial: idBranch,
        idCampanha: idCampaign
      }
    }).then((response) => {
      if (response.success) {
        showSuccessAlert(showAlert, response.message)
        updateLoading(false);
      } else {
        showErrorAlert(showAlert, response.message)
        updateLoading(false);
      }
    });
  }

  async function createPlanning(params?: any): Promise<any> {
    return await new RestUseCase(ENDPOINT_PLANNING!).Post({ data: params }).then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Planejamento criado com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  }

  async function editPlanning(params: { model: any; id: number }): Promise<any> {
    return await new RestUseCase(ENDPOINT_PLANNING! + '/' + params.id).Put({ data: params.model }).then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Planejamento editado com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  }

  async function removePlanning(params?: number): Promise<any> {
    await new RestUseCase(ENDPOINT_PLANNING! + '/' + params).Delete().then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Planejamento excluido com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
      }
    })
  }

  async function listAllPlanning(): Promise<any> {
    return await new RestUseCase(ENDPOINT_PLANNING!).Get().then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Planjemanetos listados com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  }

  const graphicHistory = async (id: number) => {
    return await new RestUseCase(`${ENDPOINT_ANALYTICAL}/historico/${id}`).Get().then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Historico encontrado com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  };
  const observationItem = async (params: { fitStrCodFabricante: number; filial: number }) => {
    return await new RestUseCase(`${END_POINT_ITEM}/${params.fitStrCodFabricante}/observacoes?filiais=${params.filial}`).Get().then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Observação encontrada com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  };
  const movement = async (params: { codigoItem: number; idFilial: number; typeMoviment: 'compra' | 'venda' }) => {
    return await new RestUseCase(`${END_POINT_MOVEMENT}/${params.typeMoviment}?codigoItem=${params.codigoItem}&idFilial=${params.idFilial}`).Get().then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Dados encotrados com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  };
  const correlate = async (params: { codigoItem: number }) => {
    return await new RestUseCase(`${END_POINT_CORRELATE}/?codigo=${params.codigoItem}`).Get().then((response) => {
      if (response.statusCode !== 500) {
        showSuccessAlert(showAlert, "Itens correlatos encontrados com sucesso")
        return handleRequest(response);
      } else {
        updateLocalStorege(LOCALSTORAGE_PLANNING, []);
        showErrorAlert(showAlert, "Ops!!! Algo deu errado tente novamente mais tarde...")
        return response
      }
    })
  };

  return (
    <>
      {showScreen ?
        <ActiveCampaignsPage
          isLoading={isLoading}
          loggedUserDataRole={loggedUserData?.role!}
          listAnalitycal={listAnalitycal}
          list={list}
          save={save}
          getActiveCampaignsTotals={getActiveCampaignsTotals}
          removePendingQuantities={removePendingQuantities}
          sendOrder={sendOrder}
          branchOptions={branchOptions}
          campaignsOptions={campaignsOptions}
          findCampaigns={findCampaigns}
          createPlanning={createPlanning}
          editPlanning={editPlanning}
          removePlanning={removePlanning}
          listAllPlanning={listAllPlanning}
          optionsFiltersDefault={ActiveCampaignsPlanningAdapter}
          correlate={correlate}
          graphicHistory={graphicHistory}
          movement={movement}
          observationItem={observationItem}
        />
        :
        <ErrorNotAuthorizedPage />
      }
    </>
  )
}