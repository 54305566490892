import styled from "styled-components";

export const FixedHeader = styled.div`

    overflow: auto; 

    thead th {
        position: sticky; 
        top: 0; z-index: 0; 
    }
        th     { 
        background:#F0F0F0; 
    }`