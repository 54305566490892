import { TableDescriptions } from "../../../@types/interfaces/table-interface";

export const ColumnsActiveCampaignAdapter: TableDescriptions[] = [
  { align: "center", order: 1, id: 1, dataField: "fitStrCodFabricante", text: "Sufixo", hidden: false, fixed: true },
  { align: "left", order: 2, id: 2, dataField: "fitStrNomeItemCampanha", text: "Descrição", hidden: false, fixed: true },
  { align: "left", order: 3, id: 3, dataField: "ALERTA", text: "ALERTA", hidden: false, fixed: true },
  { align: "center", order: 4, id: 4, dataField: "aacStrDeltaLegenda", text: "DeltaLegenda", hidden: true, fixed: true },
  { align: "center", order: 5, id: 5, dataField: "precoComStComDesconto", text: "Preco Com ST com Desconto", hidden: false, numberFormat: "currency" },
  { align: "right", order: 6, id: 6, dataField: "arqFltCustoMedio", text: "Cst Méd", hidden: false, numberFormat: "currency" },
  { align: "center", order: 7, id: 7, dataField: "aacFltDelta", text: "Var Prç Camp x Cst Méd", hidden: false, fixed: true, numberFormat: "percent" },
  { align: "center", order: 8, id: 8, dataField: "qtdPendente", text: "Qtd *", hidden: false, fixed: true },
  { align: "right", order: 9, id: 9, dataField: "pitIntQme", text: "Qme =", hidden: false },
  { align: "right", order: 10, id: 10, dataField: "doubTotal", text: "Total", hidden: false, numberFormat: "currency" },
  { ordination: { ordinationName: "caaIntQuantidade", ordinationValue: "" }, align: "center", order: 11, id: 11, dataField: "caaIntQuantidade", text: "Qtd Sugerida", hidden: false, fixed: true },
  { align: "center", order: 12, id: 12, dataField: "qtdIntencoes", text: "Qtd Enviada", hidden: false, fixed: true },
  { ordination: { ordinationName: "ppiFltDesconto", ordinationValue: "" }, align: "center", order: 13, id: 13, dataField: "ppiFltDesconto", text: "Desconto", hidden: false, numberFormat: "percent" },
  { align: "center", order: 14, id: 14, dataField: "precoSemStSemDesconto", text: "Preco Sem ST", hidden: false, numberFormat: "currency" },
  { align: "center", order: 15, id: 15, dataField: "precoSemStComDesconto", text: "Preco Sem ST com Desconto", hidden: false, numberFormat: "currency" },
  { align: "center", order: 16, id: 16, dataField: "precoComStSemDesconto", text: "Preco com ST", hidden: false, numberFormat: "currency" },
  { align: "right", order: 17, id: 17, dataField: "arqFltPrecoVenda", text: "Prç Vnd", hidden: true, typeField: "monetary" },
  { align: "right", order: 18, id: 18, dataField: "arqFltPrecoReposicao", text: "Preço Reposição", hidden: true, typeField: "monetary" },
  { align: "right", order: 19, id: 19, dataField: "arqDtmUltimaVenda", text: "Ult Ven", hidden: true, typeField: "dateTime" },
  { align: "right", order: 20, id: 20, dataField: "arqDtmUltimaCompra", text: "Ult Com", hidden: true, typeField: "dateTime" },
  { align: "center", order: 21, id: 21, dataField: "demandas", text: "Demandas", hidden: true },
  { align: "right", order: 22, id: 22, dataField: "caaFltMedia", text: "Média", hidden: false },
  { align: "right", order: 23, id: 23, dataField: "caaFltPMes", text: "PMês", hidden: false },
  { align: "right", order: 24, id: 24, dataField: "caaIntQtdEstoque", text: "Est", hidden: false },
  { align: "right", order: 25, id: 25, dataField: "caaIntQtdPendente", text: "Pen", hidden: false },
  { align: "right", order: 26, id: 26, dataField: "arqIntQtdBloqueada", text: "Bloq", hidden: false },
  { align: "right", order: 27, id: 27, dataField: "caaIntQtdPecaExcesso", text: "Exc", hidden: false },
  { align: "right", order: 28, id: 28, dataField: "caaIntPontuacao", text: "Ppt", hidden: false },
  { align: "left", order: 29, id: 29, dataField: "caaChaCurva", text: "Cvf", hidden: false },
  { align: "left", order: 30, id: 30, dataField: "caaChaNovaCurva", text: "NCvf", hidden: false },
  { align: "center", order: 31, id: 31, dataField: "caaChaStatus", text: "Status", hidden: false, fixed: false },
  { align: "center", order: 32, id: 32, dataField: "fltValorPedido", text: "Valor Pedido", hidden: false, fixed: false, numberFormat: "currency" },
  { align: "center", order: 33, id: 33, dataField: "fitChaLinha", text: "Base", hidden: true },
  { align: "center", order: 34, id: 34, dataField: "filIntIdFilial", text: "Fil", hidden: true, fixed: false },
  { align: "center", order: 35, id: 35, dataField: "obsStrTipo", text: "Observação", hidden: false, fixed: true },
  { align: "center", order: 36, id: 36, dataField: "arqIntCodIteCliente", text: "ID", hidden: false, fixed: true },
]